import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";
import BN from "@src/utils/BN";
import { APR_REFERENCE_ADDRESS, NODE_ADDRESS, NODE_CALLER_ADDRESS, MEV_FUND_ADDRESS } from "@src/constants";
import nodeService from "@src/services/nodeService";

class OverviewStore {
  public readonly rootStore: RootStore;

  nodeBalanceLoading = false;
  setNodeBalanceLoading = (value: boolean) => (this.nodeBalanceLoading = value);

  public nodeBalance: BN | null = null;
  setNodeBalance = (value: BN) => (this.nodeBalance = value);

  aprLoading = false;
  setAprLoading = (value: boolean) => (this.aprLoading = value);

  public wavesApr: BN | null = null;
  setWavesApr = (value: BN) => (this.wavesApr = value);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    Promise.all([this.refreshNodeBalance(), this.refreshApr()]);
  }

  refreshNodeBalance = async (force = false) => {
    if (!force && this.nodeBalanceLoading) return;
    this.setNodeBalanceLoading(true);
    const nodeBalance = await nodeService.getEffectiveBalance(NODE_ADDRESS);
    this.setNodeBalance(new BN(nodeBalance));
    this.setNodeBalanceLoading(false);
  }

  refreshApr = async (force = false) => {
    if (!force && this.aprLoading) return;

    const transactions = await nodeService.transactions(APR_REFERENCE_ADDRESS, 100);
    if (transactions === null) return;

    const today = new Date();
    // let monthAgo = new Date(today.setDate(today.getDate() - 30));
    // let weekAgo = new Date(today.setDate(today.getDate() - 7));
    let dayAgo = new Date(today.setDate(today.getDate() - 1));
    // const monthAgoTimestamp = monthAgo.getTime();
    // const weekAgoTimestamp = weekAgo.getTime();
    const dayAgoTimestamp = dayAgo.getTime();
    const payoutTransactions = transactions.filter(x =>
      ((x.type === 16 && (x.sender === NODE_ADDRESS || x.sender === NODE_CALLER_ADDRESS || x.sender === MEV_FUND_ADDRESS)) ||
        (x.type === 11 && (x.sender === NODE_ADDRESS))) && x.timestamp > dayAgoTimestamp);

    const wavesRewards = payoutTransactions
      .reduce((sum, current) => sum +
        (current.stateChanges?.transfers?.find(x => x.asset === null && x.address === APR_REFERENCE_ADDRESS)?.amount ?? 0) +
        (current?.transfers?.find(x => current.assetId === null && x.recipient === APR_REFERENCE_ADDRESS)?.amount ?? 0), 0);

    const wavesMonthlyApr = new BN(wavesRewards).times(365/* / 30*/);
    this.setWavesApr(wavesMonthlyApr);
  }
}

export default OverviewStore;
