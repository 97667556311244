import { Observer, observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Column, Row } from "@src/components/Flex";
import Layout from "@src/components/Layout";
import SizedBox from "@src/components/SizedBox";
import Text from "@components/Text";
import { TestnetVMProvider, useTestnetVM } from "./TestnetVM";
import Input from "@src/components/Input";
import Button from "@src/components/Button";
import Loading from "@src/components/Loading";
import { useStores } from "@src/stores";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  min-height: 100%;
  max-width: calc(1160px + 32px);
  margin-bottom: 24px;
  margin-top: 40px;
  text-align: left;
  @media (min-width: 880px) {
    margin-top: 56px;
  }
`;

const Body = styled.div`
  width: 100%;
  @media (min-width: 880px) {
    width: 40%;
  }
`;

const AdaptiveText = styled(Text)`
  @media (min-width: 880px) {
    max-width: 100%;
  }
`;

const TestnetImpl: React.FC = () => {
  const vm = useTestnetVM();
  const { accountStore } = useStores();

  return (
    <Layout>
      <Observer>
        {() => (
          <Root>
            <Column crossAxisSize="max" alignItems="center">
              <Body>
                <Text weight={500} size="large">
                  Testnet rewards
                </Text>

                <SizedBox height={8} />

                <AdaptiveText fitContent textAlign="left" type="secondary">
                  Enter testnet address (starting with 3N or 3M) to receive WAVES and UNITS tokens.
                </AdaptiveText>

                <AdaptiveText fitContent textAlign="left" type="secondary">
                  Rewards will be shared proportionally between testnet participants proportionally to their leased WAVES in mainnet.
                </AdaptiveText>

                <SizedBox height={24} />

                <Input
                  height={100}
                  placeholder={`Waves address ("3M or 3N")…`}
                  value={vm.testnetAddress ?? ""}
                  onChange={(e) => vm.setTestnetAddress(e.target.value)}
                  errorText={"Try an address starting with “3M” or “3N”"}
                />

                <SizedBox height={24} />

                <Row alignItems="center" justifyContent="space-between">
                  <Text type="secondary">Transaction fee</Text>
                  <Text textAlign="right">0.005 WAVES</Text>
                </Row>

                <SizedBox height={8} />

                {!vm.loading ? (
                  accountStore.address === null ? (<Button
                    fixed
                    size="large"
                    onClick={() => accountStore.setLoginModalOpened(true)}
                  >
                    Connect wallet
                  </Button>) : (
                    <Button fixed onClick={vm.apply} disabled={!vm.canApply}>
                      {accountStore.address === null ? "Connect wallet" : "Apply"}
                    </Button>
                  )
                ) : (
                  <Button disabled fixed>
                    Transaction in progress <Loading />
                  </Button>
                )}
              </Body>
            </Column>
          </Root>
        )}
      </Observer>
    </Layout>
  );
}

const Testnet: React.FC = () => {
  return (
    <TestnetVMProvider>
      <TestnetImpl />
    </TestnetVMProvider>
  );
};

export default observer(Testnet);