import React, { useMemo } from "react";
import { makeAutoObservable, reaction } from "mobx";
import { RootStore, useStores } from "@stores";
import { useVM } from "@src/hooks/useVM";
import { getExplorerLink } from "@src/utils/chainUtil";

interface IProps {
  children: React.ReactNode;
}

const ctx = React.createContext<TestnetVM | null>(null);

export const TestnetVMProvider: React.FC<IProps> = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(() => new TestnetVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useTestnetVM = () => useVM(ctx);

class TestnetVM {
  loading: boolean = false;
  private _setLoading = (l: boolean) => (this.loading = l);

  public testnetAddress: string | null = null;
  public setTestnetAddress = (value: string | null) => (this.testnetAddress = value);

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    reaction(
      () => this.rootStore.testnetStore.loading,
      (loading) => this.updateTestnet(loading)
    );
    reaction(
      () => this.rootStore.accountStore.address,
      () => this.refresh()
    );
    this.updateTestnet(false);
  }

  get canApply(): boolean {
    return this.testnetAddress !== null &&
      this.testnetAddress.length === 35 &&
      (this.rootStore.testnetStore.testnetAddress === null || this.rootStore.testnetStore.testnetAddress !== this.testnetAddress) &&
      (this.testnetAddress.startsWith("3M") || this.testnetAddress.startsWith("3N"))
  }

  apply = async () => {
    if (!this.canApply) return;

    this._setLoading(true);
    const { accountStore, notificationStore } = this.rootStore;

    await accountStore
      .invoke({
        dApp: "3PFnttKjHE9CB6P3dMb3WiLVMhxvVMWGzjT",
        payment: [],
        call: {
          function: "setTestnetAddress",
          args: [
            { type: "string", value: this.testnetAddress as string },
          ]
        }
      })
      .then((txId) => {
        if (txId !== null) {
          notificationStore.notify(
            "",
            {
              type: "success",
              title: `Address was successfully applied`,
              link: getExplorerLink(txId),
              linkTitle: "View on Explorer",
            }
          );
        }
      })
      .catch((e) => {
        notificationStore.notify(e.message ?? JSON.stringify(e), {
          type: "error",
          title: "Transaction is not completed",
        });
      })
      .then(() => this.refresh())
      .finally(() => this._setLoading(false))
  }

  private refresh = async () => {
    this.rootStore.testnetStore.refresh(true);
  }

  private updateTestnet = async (loading: boolean) => {
    if (loading) return;

    const testnetStore = this.rootStore.testnetStore;
    this.setTestnetAddress(testnetStore.testnetAddress);
  }
}