import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";
import nodeService from "@src/services/nodeService";

class TestnetStore {
  public readonly rootStore: RootStore;

  loading = false;
  setLoading = (value: boolean) => (this.loading = value);

  public testnetAddress: string | null = null;
  private setTestnetAddress = (value: string | null) => (this.testnetAddress = value);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    Promise.all([this.refresh()]);
  }

  refresh = async (force = false) => {
    if (!force && this.loading) return;

    this.setLoading(true);

    const accountAddress = this.rootStore.accountStore.address;

    const testnetData = await nodeService.nodeKeysRequest("3PFnttKjHE9CB6P3dMb3WiLVMhxvVMWGzjT", [
      `testnetAddress_${accountAddress}`,
    ]);
    
    if (testnetData === null || testnetData.length === 0) {
      this.setTestnetAddress(null);
    } else {
      const address = testnetData[0]
      this.setTestnetAddress(address.value as string);
    }

    this.setLoading(false);
  }
}

export default TestnetStore;
