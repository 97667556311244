import styled from "@emotion/styled";
import { Row, Column } from "@src/components/Flex";
import SizedBox from "@src/components/SizedBox";
import Text from "@components/Text";
import SquareTokenIcon from "@src/components/SquareTokenIcon";
import { observer } from "mobx-react-lite";

interface IProps {
  logo: string;
  ticker: string;
}

const Root = styled.div`
  display: flex;
  padding: 16px;
  background: ${({ theme }) => theme.colors.primary100};
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  height: 30px;
`;

const MinedByNode: React.FC<IProps> = (props) => {
  return (
    <Root>
      <Row alignItems="center">
        <SquareTokenIcon size="small" src={props.logo} />
        <SizedBox width={4} />
        <Column>
          <Text weight={500}>{props.ticker}</Text>
        </Column>
      </Row>
    </Root>
  );
}

export default observer(MinedByNode);