import styled from "@emotion/styled";
import React from "react";
import { Column, Row } from "@components/Flex";
import Text from "@components/Text";
import Card from "@components/Card";
import SizedBox from "@components/SizedBox";
import { observer } from "mobx-react-lite";
import { useLeasingVM } from "../LeasingVM";
import Button from "@src/components/Button";
import Loading from "@src/components/Loading";
import { useStores } from "@src/stores";
import Input from "@src/components/Input";
import { useTheme } from "@emotion/react";
import MinedByNode from "./MinedByNode";
import SwapToAsset from "./SwapToAsset";
import { CHAIN_ID } from "@src/constants";
import Img from "@src/components/Img";
import tokenLogos from "@src/constants/tokenLogos";
import Tooltip from "@components/Tooltip";
import { ReactComponent as InfoIcon } from "@src/assets/icons/info.svg";
import SelectForAll from "./SelectForAll";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
`;

const ArrowImg = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  right: calc(50% - 16px);
  top: 16px;
  transform: rotate(-90deg);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  row-gap: 8px;
  column-gap: 4px;
`

const Reset = styled.div`
  position: relative;
  width: 0%;
  right: 40px;
  top: 15px;
  cursor: pointer;
`

const Delegate = styled.div`
display: flex;
flex-direction: row;
`

const PayoutSettings: React.FC = () => {
  const vm = useLeasingVM();
  const theme = useTheme();
  const { accountStore } = useStores();

  return (
    <Root>
      <Text weight={500} type="secondary">
        Payout settings
      </Text>
      <SizedBox height={8} />

      <Card>
        <Grid>
          <Row mainAxisSize="fit-content" alignItems="center">
            <Text nowrap type="secondary">Mined by node</Text>
            <SizedBox width={4} />
            <Tooltip
              containerStyles={{ display: "flex", alignItems: "center" }}
              content={
                <Text>
                  Source tokens received by the node via blocks mining from WAVES and UNITS networks
                </Text>
              }
            >
              <InfoIcon style={{ marginLeft: 8 }} />
            </Tooltip>
          </Row>
          <Text />
          <Row mainAxisSize="fit-content" alignItems="center">
            <Text nowrap type="secondary">You receive</Text>
            <SizedBox width={4} />
            <Tooltip
              containerStyles={{ display: "flex", alignItems: "center" }}
              content={
                <Text>
                  Tokens will be swapped to whatever you select via Puzzle Aggregator
                </Text>
              }
            >
              <InfoIcon style={{ marginLeft: 8 }} />
            </Tooltip>
          </Row>

          <MinedByNode logo={tokenLogos.WAVES} ticker="WAVES" />
          <SizedBox style={{ position: "relative" }}>
            <ArrowImg src={theme.images.icons.limitOrderArrow} />
          </SizedBox>
          <SwapToAsset asset={vm.swapWavesTo} setAsset={asset => vm.setSwapWavesTo(asset)} />

          <MinedByNode logo={tokenLogos.WAVESDLP} ticker="WAVESDLP" />
          <SizedBox style={{ position: "relative" }}>
            <ArrowImg src={theme.images.icons.limitOrderArrow} />
          </SizedBox>
          <SwapToAsset asset={vm.swapWavesDlpTo} setAsset={asset => vm.setSwapWavesDlpTo(asset)} />

          <MinedByNode logo={tokenLogos.UNIT0} ticker="UNIT0" />
          <SizedBox style={{ position: "relative" }}>
            <ArrowImg src={theme.images.icons.limitOrderArrow} />
          </SizedBox>
          <SwapToAsset asset={vm.swapUnit0To} setAsset={asset => vm.setSwapUnit0To(asset)} />

          <Button fixed size="medium" onClick={() => vm.setDefaults()}>Default</Button>
          <Text></Text>
          <SelectForAll setAsset={asset => vm.setSwapForAll(asset)} />
        </Grid>

        <SizedBox height={16} />

        <Row mainAxisSize="fit-content" alignItems="center">
          <Text nowrap type="secondary">Delegate to</Text>
          <SizedBox width={4} />
          <Tooltip
            containerStyles={{ display: "flex", alignItems: "center" }}
            content={
              <Text>
                Your leasing rewards will be sent to the specified address instead of the one from which lease is made.
                You can remove delegation at any time
              </Text>
            }
          >
            <InfoIcon style={{ marginLeft: 8 }} />
          </Tooltip>
        </Row>

        <SizedBox height={10} />
        <Delegate>
          <Column crossAxisSize="max">
            <Input
              height={100}
              placeholder={`Waves address (${CHAIN_ID === 'W' ? "3P" : "3M or 3N"})…`}
              value={vm.delegateTo ?? ""}
              onChange={(e) => vm.setDelegateTo(e.target.value)}
              error={vm.delegateToError}
              errorText={vm.delegateToErrorText}
            />
          </Column>
          <Reset onClick={() => vm.setDelegateTo(accountStore.address)}>
            <Img src={theme.images.icons.reset} alt="arrow" />
          </Reset>
        </Delegate>

        <SizedBox height={24} />
        <Row alignItems="center" justifyContent="space-between">
          <Text type="secondary">Transaction fee</Text>
          <Text textAlign="right">0.005 WAVES</Text>
        </Row>
        <SizedBox height={24} />
        {!vm.loading ? (
          accountStore.address === null ? (<Button
            fixed
            size="large"
            onClick={() => accountStore.setLoginModalOpened(true)}
          >
            Connect wallet
          </Button>) : (
            <Button fixed onClick={vm.applySettings} disabled={!vm.canApplySettings}>
              {accountStore.address === null ? "Connect wallet" : "Apply settings"}
            </Button>
          )
        ) : (
          <Button disabled fixed>
            Transaction in progress <Loading />
          </Button>
        )}

      </Card>
    </Root>
  );
};
export default observer(PayoutSettings);
