import styled from "@emotion/styled";
import React from "react";
import Text from "@components/Text";
import Card from "@components/Card";
import SizedBox from "@components/SizedBox";
import { Column, Row } from "@src/components/Flex";
import { observer } from "mobx-react-lite";
import BN from "@src/utils/BN";
import { useLeasingVM } from "./LeasingVM";
import Tooltip from "@components/Tooltip";
import { ReactComponent as InfoIcon } from "@src/assets/icons/info.svg";
import PayoutCountdown from "@src/components/PayoutCountdown";
import { useStores } from "@src/stores";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: 370px) {
    flex-direction: row;
  }
`;
const Overview: React.FC = () => {
  const vm = useLeasingVM();
  const { pricesStore } = useStores();

  const nodeBalance = vm.nodeBalance !== null
    ? BN.formatUnits(vm.nodeBalance, vm.wavesToken.decimals).toFormat(2)
    : null;
  const leasingApr = vm.wavesApr !== null
    ? BN.formatUnits(vm.wavesApr, 6).toFormat(2).concat(" %")
    : null;
  const wavesPrice = pricesStore.wavesPrice !== null
    ? `$ ${pricesStore.wavesPrice.toFormat(4)}`
    : null;

  return (
    <Root>
      <Text weight={500} type="secondary">
        Overview
      </Text>
      <SizedBox height={8} />
      <Card>
        <Container>
          <Row alignItems="center">
            <Column justifyContent="space-between">
              <Text nowrap type="secondary" size="small">
                Node balance (WAVES)
              </Text>
              <Text nowrap style={{ fontSize: 20 }}>
                {nodeBalance ?? "—"}
              </Text>
            </Column>
          </Row>
          <SizedBox height={16} />
          <Row alignItems="center">
            <Column justifyContent="space-between">
              <Text nowrap type="secondary" size="small">
                APR (WAVES)
              </Text>
              <Text nowrap style={{ fontSize: 20 }}>
                {leasingApr ?? "—"}
              </Text>
            </Column>
            <SizedBox width={4} />
            <Tooltip
              containerStyles={{ display: "flex", alignItems: "center" }}
              content={
                <Text>
                  Calculated from rewards in reference account for the last day and extrapolated for the year.
                  Reference account is configured to sell WAVESDLP to WAVES
                </Text>
              }
            >
              <InfoIcon style={{ marginLeft: 8 }} />
            </Tooltip>
          </Row>
        </Container>
        <SizedBox height={16} />
        <Container>
          <Row alignItems="center">
            <Column justifyContent="space-between">
              <Text nowrap type="secondary" size="small">
                Next payout in
              </Text>
              <Text nowrap style={{ fontSize: 20 }}>
                <PayoutCountdown />
              </Text>
            </Column>
          </Row>
          <SizedBox height={16} />
          <Row alignItems="center">
            <Column justifyContent="space-between">
              <Text nowrap type="secondary" size="small">
                WAVES price
              </Text>
              <Text nowrap style={{ fontSize: 20 }}>
                {wavesPrice ?? "—"}
              </Text>
            </Column>
          </Row>
        </Container>
      </Card>
    </Root>
  );
};
export default observer(Overview);
