
import React, { useMemo } from "react";
import { makeAutoObservable, reaction } from "mobx";
import { RootStore, useStores } from "@stores";
import { useVM } from "@src/hooks/useVM";
import Balance from "@src/entities/Balance";
import BN from "@src/utils/BN";
import { CHAIN_ID, NODE_ADDRESS, SETTINGS_ADDRESS, TOKENS_BY_SYMBOL, UNIT0_ID, Unit0Asset, WAVESDLP_ID, WavesAsset, WavesDlpAsset } from "@src/constants";
import { ILeaseItem } from "@src/entities/LeaseItem";
import { getExplorerLink } from "@src/utils/chainUtil";

interface IProps {
  children: React.ReactNode;
}

const ctx = React.createContext<LeasingVM | null>(null);

export const LeasingVMProvider: React.FC<IProps> = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(() => new LeasingVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useLeasingVM = () => useVM(ctx);

class LeasingVM {
  loading: boolean = false;
  private _setLoading = (l: boolean) => (this.loading = l);

  public action: 0 | 1 = 0;
  setAction = (v: 0 | 1) => (this.action = v);

  public nodeBalance: BN | null = null;
  public setNodeBalance = (value: BN) => (this.nodeBalance = value);

  public wavesApr: BN | null = null;
  public setWavesApr = (value: BN) => (this.wavesApr = value);

  public wavesAmountToLease: BN = BN.ZERO;
  public setWavesAmountToLease = (value: BN) => (this.wavesAmountToLease = value);

  public leaseItems: ILeaseItem[] | null = null;
  private setLeaseItems = (value: ILeaseItem[]) => (this.leaseItems = value);

  public addressLeased: BN | null = null;
  private setAddressLeased = (value: BN | null) => (this.addressLeased = value);

  public swapWavesTo: Balance = WavesAsset;
  setSwapWavesTo = (value: Balance) => (this.swapWavesTo = value);

  public swapWavesDlpTo: Balance = WavesDlpAsset;
  setSwapWavesDlpTo = (value: Balance) => (this.swapWavesDlpTo = value);

  public swapUnit0To: Balance = Unit0Asset;
  setSwapUnit0To = (value: Balance) => (this.swapUnit0To = value);

  public delegateTo: string | null = null;
  setDelegateTo = (value: string | null) => (this.delegateTo = value);

  public aggregatorAssets: Balance[] = [];
  setAggregatorAssets = (value: Balance[]) => (this.aggregatorAssets = value);

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    reaction(
      () => this.rootStore.accountStore.leases,
      () => this.updateAddressLeasingInfo()
    );
    reaction(
      () => this.rootStore.overviewStore.nodeBalance,
      () => this.updateOverview()
    );
    reaction(
      () => this.rootStore.overviewStore.wavesApr,
      () => this.updateOverview()
    );
    reaction(
      () => this.rootStore.settingsStore.settingsLoading,
      (loading) => this.updateSettings(loading)
    );
    reaction(
      () => this.rootStore.accountStore.address,
      () => this.refresh()
    );
    this.updateAddressLeasingInfo();
    this.updateOverview();
    this.updateSettings(false);
  }

  public get wavesToken() {
    return TOKENS_BY_SYMBOL.WAVES;
  }

  get delegateToError() {
    if (this.delegateTo === null || this.delegateTo.length !== 35) {
      return false;
    }

    if (CHAIN_ID === "W") {
      return !this.delegateTo.startsWith("3P");
    }

    return !this.delegateTo.startsWith("3M") && !this.delegateTo.startsWith("3N");
  }

  get delegateToErrorText() {
    if (CHAIN_ID === "W") {
      return "Outside of Waves. Try an address starting with “3P”.";
    } else {
      return "Outside of Waves. Try an address starting with “3M” or “3N”.";
    }
  }

  get wavesBalance() {
    const { accountStore } = this.rootStore;
    const wavesBalance = accountStore.findBalanceByAssetId(
      this.wavesToken.assetId
    );
    return wavesBalance ? wavesBalance : new Balance(this.wavesToken);
  }

  get tokenStakeInputInfo() {
    const { address } = this.rootStore.accountStore;
    const onMaxClick =
      address !== null
        ? () =>
          this.setWavesAmountToLease(this.wavesBalance.balance ?? BN.ZERO)
        : undefined;
    return {
      selectable: false,
      decimals: this.wavesToken.decimals,
      amount: this.wavesAmountToLease,
      setAmount: this.setWavesAmountToLease,
      assetId: this.wavesToken.assetId,
      balances: [this.wavesBalance],
      onMaxClick,
    };
  }

  get canLease(): boolean {
    return (
      this.wavesAmountToLease.gt(0) &&
      this.wavesAmountToLease.lte(this.wavesBalance.balance ?? BN.ZERO) &&
      this.wavesBalance.balance?.gt(0) !== null
    );
  }

  get canApplySettings(): boolean {
    return (
      (this.swapWavesTo.assetId !== this.rootStore.settingsStore.swapWavesTo?.assetId ||
        this.swapWavesDlpTo.assetId !== this.rootStore.settingsStore.swapWavesDlpTo?.assetId ||
        this.swapUnit0To.assetId !== this.rootStore.settingsStore.swapUnit0To?.assetId ||
        this.delegateTo !== this.rootStore.settingsStore.delegateTo) && !this.delegateToError
    );
  }

  lease = async () => {
    if (!this.canLease) return;

    this._setLoading(true);
    const { wavesToken, wavesAmountToLease, addressLeased, rootStore } = this;
    const { accountStore, notificationStore } = rootStore;
    const wavesAmount = BN.formatUnits(
      wavesAmountToLease,
      wavesToken.decimals
    );

    await accountStore
      .lease({
        amount: wavesAmountToLease.toString(),
        recipient: NODE_ADDRESS
      })
      .then((txId) => {
        txId && this.setAddressLeased(
          addressLeased?.plus(wavesAmount) ?? BN.ZERO
        );

        txId && notificationStore.notify(
          `Your lease will be activated in 1000 blocks (~17 hours)`,
          {
            type: "success",
            title: `${wavesAmount.toFormat(2)} WAVES successfully leased`,
            link: getExplorerLink(txId),
            linkTitle: "View on Explorer",
          }
        );
      })
      .catch((e) => {
        notificationStore.notify(e.message ?? JSON.stringify(e), {
          type: "error",
          title: "Transaction is not completed",
        });
      })
      .then(() => this.refresh())
      .finally(() => this._setLoading(false));
  }

  cancelLease = async (leaseItem: ILeaseItem) => {
    this._setLoading(true);
    const { wavesToken, addressLeased, rootStore } = this;
    const { accountStore, notificationStore } = rootStore;
    const wavesAmount = BN.formatUnits(
      leaseItem.amount,
      wavesToken.decimals
    );
    await accountStore
      .leaseCancel({
        leaseId: leaseItem.leaseId
      })
      .then((txId) => {
        txId && this.setAddressLeased(
          addressLeased?.minus(wavesAmount) ?? BN.ZERO
        );
        txId && notificationStore.notify(
          `You can track your available WAVES in 'My balances' section`,
          {
            type: "success",
            title: `${wavesAmount.toFormat(2)} WAVES lease successfully cancelled`,
            link: getExplorerLink(txId),
            linkTitle: "View on Explorer",
          }
        );
      })
      .catch((e) => {
        notificationStore.notify(e.message ?? JSON.stringify(e), {
          type: "error",
          title: "Transaction is not completed",
        });
      })
      .then(() => this.refresh())
      .finally(() => this._setLoading(false))
  }

  applySettings = async () => {
    this._setLoading(true);

    const { accountStore, notificationStore } = this.rootStore;

    await accountStore
      .invoke({
        dApp: SETTINGS_ADDRESS,
        payment: [],
        call: {
          function: "applySettings",
          args: [
            {
              type: "list",
              value: [
                { type: "string", value: "WAVES" },
                { type: "string", value: "WAVES" },
                { type: "string", value: "UNIT0" },
              ]
            },
            {
              type: "list",
              value: [
                { type: "string", value: "WAVES" },
                { type: "string", value: WAVESDLP_ID },
                { type: "string", value: UNIT0_ID },
              ] 
            },
            {
              type: "list",
              value: [
                { type: "string", value: "WAVES" },
                { type: "string", value: "WAVES" },
                { type: "string", value: "WAVES" },
              ] 
            },
            {
              type: "list",
              value: [
                { type: "string", value: this.swapWavesTo.assetId },
                { type: "string", value: this.swapWavesDlpTo.assetId },
                { type: "string", value: this.swapUnit0To.assetId },
              ]
            },
            {
              type: "list",
              value: [
                { type: "string", value: "transfer" },
                { type: "string", value: "transfer" },
                { type: "string", value: "transfer" },
              ]
            },
            {
              type: "list", 
              value: [
                { type: "string", value: this.delegateTo ?? accountStore.address! },
                { type: "string", value: this.delegateTo ?? accountStore.address! },
                { type: "string", value: this.delegateTo ?? accountStore.address! },
              ]
            },
          ]
        }
      })
      .then((txId) => {
        if (txId !== null) {
          notificationStore.notify(
            "",
            {
              type: "success",
              title: `Settings were successfully applied`,
              link: getExplorerLink(txId),
              linkTitle: "View on Explorer",
            }
          );
        }
      })
      .catch((e) => {
        notificationStore.notify(e.message ?? JSON.stringify(e), {
          type: "error",
          title: "Transaction is not completed",
        });
      })
      .then(() => this.refresh())
      .finally(() => this._setLoading(false))
  }
  
  setDefaults = async () => {
    this.setSwapWavesTo(WavesAsset);
    this.setSwapWavesDlpTo(WavesDlpAsset);
    this.setSwapUnit0To(Unit0Asset);
  }

  setSwapForAll = async (asset: Balance) => {
    this.setSwapWavesTo(asset);
    this.setSwapWavesDlpTo(asset);
    this.setSwapUnit0To(asset);
  }

  private refresh = async () => {
    this.rootStore.overviewStore.refreshNodeBalance();
    this.rootStore.accountStore.updateAccountAssets(true);
    this.rootStore.settingsStore.refreshSettings();
  }

  private updateOverview = async () => {
    const overviewStore = this.rootStore.overviewStore;
    const nodeBalance = overviewStore.nodeBalance;
    if (nodeBalance !== null) {
      this.setNodeBalance(nodeBalance)
    }

    if (overviewStore.wavesApr !== null) {
      this.setWavesApr(overviewStore.wavesApr);
    }
  }

  private updateAddressLeasingInfo = async () => {
    if (this.rootStore.accountStore.address === null) {
      this.setLeaseItems([]);
      this.setAddressLeased(null);
      return;
    }

    const leased = this.rootStore.accountStore.leases.reduce((sum, current) => sum.plus(current.amount), BN.ZERO);
    this.setLeaseItems(this.rootStore.accountStore.leases);
    this.setAddressLeased(BN.formatUnits(leased, this.wavesToken.decimals));
  }

  private updateSettings = async (loading: boolean) => {
    if (loading) return;

    const settingsStore = this.rootStore.settingsStore;
    this.setAggregatorAssets(settingsStore.aggregatorAssets);
    this.setSwapWavesTo(settingsStore.swapWavesTo);
    this.setSwapWavesDlpTo(settingsStore.swapWavesDlpTo);
    this.setSwapUnit0To(settingsStore.swapUnit0To);
    this.setDelegateTo(settingsStore.delegateTo);
  }
}