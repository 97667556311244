import { observer } from "mobx-react-lite";
import { useState } from "react";
import TokenSelectModal from "@src/components/TokensSelectModal";
import { useLeasingVM } from "../LeasingVM";
import Balance from "@src/entities/Balance";
import Button from "@src/components/Button";

interface IProps {
  setAsset: (asset: Balance) => void;
}

const SelectForAll: React.FC<IProps> = (props) => {
  const vm = useLeasingVM();
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Button fixed size="medium" onClick={() => setOpenModal(true)}>Select for all</Button>

      <TokenSelectModal
        visible={openModal}
        onSelect={props.setAsset}
        balances={vm.aggregatorAssets}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default observer(SelectForAll);