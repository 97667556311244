import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";
import { SETTINGS_ADDRESS, TOKENS_LIST, UNIT0_ID, WAVESDLP_ID, Unit0Asset, WavesAsset, WavesDlpAsset } from "@src/constants";
import nodeService from "@src/services/nodeService";
import aggregatorService from "@src/services/aggregatorService";
import Balance from "@src/entities/Balance";

class SettingsStore {
  public readonly rootStore: RootStore;

  settingsLoading = false;
  setSettingsLoading = (value: boolean) => (this.settingsLoading = value);

  public aggregatorAssets: Balance[] = [];
  setAggregatorAssets = (value: Balance[]) => (this.aggregatorAssets = value);

  public swapWavesTo: Balance = WavesAsset;
  setSwapWavesTo = (value: Balance) => (this.swapWavesTo = value);

  public swapWavesDlpTo: Balance = WavesDlpAsset;
  setSwapWavesDlpTo = (value: Balance) => (this.swapWavesDlpTo = value);

  public swapUnit0To: Balance = Unit0Asset;
  setSwapUnit0To = (value: Balance) => (this.swapUnit0To = value);

  public delegateTo: string | null = null;
  setDelegateTo = (value: string | null) => (this.delegateTo = value);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    Promise.all([this.refreshSettings()]);
  }

  get isDefault(): boolean {
    return (
      this.swapWavesTo.assetId === "WAVES" &&
      this.swapWavesDlpTo.assetId === WAVESDLP_ID &&
      this.swapUnit0To.assetId === UNIT0_ID &&
      this.delegateTo === this.rootStore.accountStore.address
    )
  };

  refreshSettings = async (force = false) => {
    if (!force && this.settingsLoading) return;

    this.setSettingsLoading(true);

    const availableAssetIds = (await aggregatorService.getAvailableAssetsIds());

    const aggregatorAssets = availableAssetIds.map(id => {
      const localAsset = TOKENS_LIST.find(x => x.assetId === id);
      if (localAsset !== undefined) {
        return new Balance(
          {
            symbol: localAsset.symbol,
            assetId: id,
            name: localAsset.name,
            decimals: localAsset.decimals,
            logo: localAsset.logo,
            category: localAsset.category,
          });
      }

      return new Balance({
        symbol: "",
        assetId: id,
        name: "",
        decimals: 0,
      });
    }) ?? []

    this.setAggregatorAssets(aggregatorAssets);

    const accountAddress = this.rootStore.accountStore.address;
    const settingsData = await nodeService.nodeKeysRequest(SETTINGS_ADDRESS, [
      `settings_${accountAddress}_WAVES_WAVES`,
      `settings_${accountAddress}_WAVES_${WAVESDLP_ID}`,
      `settings_${accountAddress}_UNIT0_${UNIT0_ID}`,
    ]);

    if (settingsData === null || settingsData.length === 0) {
      this.setSwapWavesTo(WavesAsset);
      this.setSwapWavesDlpTo(WavesDlpAsset);
      this.setSwapUnit0To(Unit0Asset);
      this.setDelegateTo(accountAddress);
    } else {
      const swapWavesTo = settingsData.find(x => x.key === `settings_${accountAddress}_WAVES_WAVES`)?.value as string ?? `WAVES_WAVES_transfer_${accountAddress}`
      const swapWavesToId = swapWavesTo.split("_")[1]
      const foundSwapWavesTo = aggregatorAssets.find(x => x.assetId === swapWavesToId);
      this.setSwapWavesTo(foundSwapWavesTo ?? WavesAsset);

      const swapWavesDlpTo = settingsData.find(x => x.key === `settings_${accountAddress}_WAVES_${WAVESDLP_ID}`)?.value as string ?? `WAVES_${WAVESDLP_ID}_transfer_${accountAddress}`
      const swapWavesDlpToId = swapWavesDlpTo.split("_")[1]
      const foundSwapWavesDlpTo = aggregatorAssets.find(x => x.assetId === swapWavesDlpToId);
      this.setSwapWavesDlpTo(foundSwapWavesDlpTo ?? WavesDlpAsset);

      const swapUnit0To = settingsData.find(x => x.key === `settings_${accountAddress}_UNIT0_${UNIT0_ID}`)?.value as string ?? `WAVES_${UNIT0_ID}_transfer_${accountAddress}`
      const swapUnit0ToId = swapUnit0To.split("_")[1]
      const foundSwapUnit0To = aggregatorAssets.find(x => x.assetId === swapUnit0ToId);
      this.setSwapUnit0To(foundSwapUnit0To ?? Unit0Asset);

      this.setDelegateTo(swapWavesDlpTo.split("_")[3]);
    }

    this.setSettingsLoading(false);
  }
}

export default SettingsStore;
