import Text from "@components/Text";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import React from "react";
import { Observer } from "mobx-react-lite";
import Layout from "@components/Layout";
import SizedBox from "@components/SizedBox";
import { Column } from "@components/Flex";
import { LeasingVMProvider } from "./LeasingVM";
import FAQ from "./FAQ";
import Overview from "./Overview";
import MyBalances from "./MyBalances";
import LeaseOrCancel from "./LeaseOrCancel";
import PayoutSettings from "./Settings/PayoutSettings";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  min-height: 100%;
  max-width: calc(1160px + 32px);
  margin-bottom: 24px;
  margin-top: 40px;
  text-align: left;
  @media (min-width: 880px) {
    margin-top: 56px;
  }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  @media (min-width: 880px) {
    grid-template-columns: auto 45ch;
    column-gap: 40px;
  }
`;

const MainBlock = styled.div`
  width: 100%;
  @media (min-width: 880px) {
    width: 100%;
  }
`;
const RightBlockMobile = styled(Column)`
  width: 100%;
  @media (min-width: 880px) {
    display: none;
  }
`;
const RightBlock = styled(Column)`
  width: 65%;
  display: none;
  @media (min-width: 880px) {
    display: flex;
  }
`;
const AdaptiveText = styled(Text)`
  @media (min-width: 880px) {
    max-width: 65%;
  }
`;

const LeasingImpl: React.FC = () => {
  return (
    <Layout>
      <Observer>
        {() => (
          <Root>
            <Text weight={500} size="large">
              WAVES Leasing
            </Text>
            <SizedBox height={8} />
            <AdaptiveText fitContent textAlign="left" type="secondary">
              Stake your WAVES to get 95% share of block reward, transaction fees, WAVESDLP, UNITS and extra profit from MEV-arbitrage.
              Choose any token you would like to be rewarded in.
            </AdaptiveText>
            <SizedBox height={8} />
            <AdaptiveText fitContent textAlign="left" type="secondary">
              Part of the miner's fees is used to buy PUZZLE and distribute it to the PUZZLE stakers.
            </AdaptiveText>
            <Body>
              <MainBlock>
                <Overview />
                <SizedBox height={10} />
                <MyBalances />
                <SizedBox height={10} />
                <LeaseOrCancel />
                <RightBlockMobile>
                  <PayoutSettings />
                </RightBlockMobile>
              </ MainBlock>
              <RightBlock>
                <PayoutSettings />
              </RightBlock>
            </Body>
            <SizedBox height={48} />
            <FAQ />
          </Root>
        )}
      </Observer>
    </Layout>
  );
}

const Leasing: React.FC = () => {
  return (
    <LeasingVMProvider>
      <LeasingImpl />
    </LeasingVMProvider>
  );
};

export default observer(Leasing);
